import {
  getLastUserId,
  getLastVisitedList,
} from '@reshima/user-local-persistence';
import { initializeTelemetry } from './initialize-telemetry';
import { initializeFirebase } from './initialize-firebase';
import { initializeClientServer } from './initialize-client-server';

export default async function initializer() {
  await initializeTelemetry();

  if (typeof window !== 'undefined') {
    const lastUserId = await getLastUserId();
    const lastVisitedList = await getLastVisitedList();
    await initializeFirebase({ lastUserId, lastVisitedList });

    initializeClientServer();
  }
}
