import { Options, initialize } from '@reshima/telemetry';
import { getAuthApp } from '@reshima/firebase';

const sessionId = Date.now();

const options: Options = {
  connectionString: process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING || '',
  consoleTraces: process.env.NEXT_PUBLIC_CONSOLE_TRACES === 'true',
  initialProperties: () => {
    const currentUser = getAuthApp()?.currentUser;

    const userDetails = currentUser && {
      userId: currentUser.uid,
    };

    return {
      ...userDetails,
      commitHash: process.env.NEXT_PUBLIC_COMMIT_SHA,
      sessionId,
    };
  },
};

export async function initializeTelemetry() {
  await initialize(options);
}
